import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContentBlock from '../components/ContentBlock';

const ContactThanksPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query ContactThanksPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms
        title="Thanks for getting in touch"
        titleTemplate={`%s${titleSuffix}`}
      >
        <meta name="robots" content="noindex, follow" />
      </HelmetDatoCms>
      <main>
        <Banner heading="Thanks for getting in touch" />
        <ContentBlock html="<p>We will get back to you shortly.</p>" />
      </main>
    </Layout>
  );
};

export default ContactThanksPage;
